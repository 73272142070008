/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

/* Body - Full Page Layout */
body, html {
    height: 100%;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    min-height: 100vh; */
    background-color: #f9f9f9;
}

/* Header */
.header {
    background-color: #38ADEF;
    padding: 5px;
    width: 100%;
    text-align: center;
}

/* Main Content - Centered Box */
.main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    background: #fff;
    padding: 30px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #ddd;
    width: 100%;
    /* max-width: 500px; */
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-header {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

/* Inputs and Select Fields */
.input-field, .select-field .css-yk16xz-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: #38ADEF;
    box-shadow: 0 0 5px rgba(56, 173, 239, 0.5);
}

.form-inline-group {
    display: flex;
    gap: 20px;
}

.form-inline-item {
    flex: 1;
}

.terms-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.terms-group input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
}

.terms-group a {
    color: #38ADEF;
    text-decoration: none;
}

.terms-group a:hover {
    text-decoration: underline;
}

/* Button */
.submit-button {
    background-color: #38ADEF;
    color: white;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
    background-color: #2d8bb7;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Footer */
.footer {
    padding: 15px;
    color: white;
    text-align: center;
    width: 100%;
}

.footer a {
    color: #fff;
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 600px) {
    .login-form {
        padding: 20px;
    }

    .form-inline-group {
        flex-direction: column;
        gap: 10px;
    }
}

/* Input Error Highlight */
.input-field.invalid {
    border-color: red;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

/* Error Text */
.error-text {
    color: red;
    font-size: 0.85rem;
    margin-top: 5px;
    display: block;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.react-tel-input {
    width: 100%;
  }
  
  .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    font-size: 1rem;
    padding-left: 50px !important;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #333;
  }
  
  .react-tel-input .flag-dropdown {
    background: white;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 5px 0 0 5px;
  }
  
